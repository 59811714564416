

/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
body {
    background: #FFFFFF;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/*===========================================================================
Developement Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 5px 15px;
}

.logo .logo-img {
    height: 45px;
    display: inline-block;
}
.logo-img {
    padding: 20px 20px;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.hipster {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("assets/images/hipster.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("assets/images/hipster2x.png") no-repeat center top;
        background-size: contain;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer;
}

.hand {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
   flex-direction: row;
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td verifically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
    .navbar {
    	border-radius: 0px!important;/*Not looking good so Overridden*/
	}
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}

/*Hiding today button*/
.uib-datepicker-popup .uib-datepicker-current{
    display:none;
}
.uib-datepicker-popup .btn-group> .btn:nth-child(2){
    margin-left:0;
    border-bottom-left-radius:3px;
    border-top-left-radius:3px;
}

.navbar {
    background-color: #ffffff;
    border-color: #ffffff;
}
.navbar  a{
    color: #001789;
    text-decoration: none;
}


.navbar-brand-msd {
    float: left;
    padding: 7px 8px;
    font-size: 18px;
    line-height: 20px;
}
.footer{
    width: 100%;
    padding: 0;
    color: #fff;
    margin-top: 105px;
}
.footerPre { padding: 35px 30px 35px 25px; color: #000000; background-color: #E6E7E8; text-align: center; }
.footerPost { padding: 15px 30px 15px 25px; background-color: #001789;}
.footer  a{
  display: inline-block;
  color: #fff;
  margin-left:10px;
}

.footer .footer-links{
	color: #fff;
    font-size: 11.5px;
    margin-top:12px
}

.footer .footer-copy{
	display: inline-block;
    color: #fff;
    font-size: 11.5px;
    margin-top:12px
}

.css-form input.ng-invalid {
  background-color: #facdce;
}
.css-form input.ng-invalid-required {
  background-color: #facdce;
}

.has-error .form-control {
  background-color: #facdce;
}

.btn-default.active {
   background-color: #001789;
   color: #ccc;
   border-color: #001789;
}

.msd-button {
   background-color: #001789;
   color: #ccc;
}

.msd-button:hover {
   background-color: #728690;
   color: #ccc;
}
.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: #337ab7;
}

.msd-thead {
    background-color: #5D5757;
    border-color:#000000;
    color: #FFFFFF;
}
md-toolbar.md-default-theme.md-hue-2:not(.md-menu-toolbar), md-toolbar.md-hue-2:not(.md-menu-toolbar) {
    background-color: rgb(33, 63, 87);
    color: rgba(255,255,255,0.87);
}

.btn-msd:hover {
    color: #fff;
    background-color: #4b5d6d;
    border-color: #4b5d6d;
}
a:hover, a:focus, a.focus {
    color: #72c9c2;
    text-decoration: none!important;
}
.btn-msd {
    color: #fff;
    background-color: #183046;
    border-color: #183046;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background-color: #4d81ab;
    border-color: #337ab7;
}

.required-error .form-control {
  background-color: #facdce;
}
.cursor-pointer{
  cursor: pointer!important
}

/* ==========================================================================
UI fixing
========================================================================== */
.nav-C-info{color: #FFF;margin-top: 32px;float: right}
.navbar{margin-bottom:0px}
.stickyFooter{ height: auto; top: auto; bottom: 0; z-index: 1000;}
.push{height:105px}
.msd-panel-primary > .panel-heading {color: #fff!important;background-color: #001789!important;border-color: #001789!important;}
.msd-panel-primary > div > .panel-body {border: 1px solid #001789;}



.panel-table > .panel-heading {padding: 2px 10px;background-color: #001789!important;color: #fff!important}
.panel-table .panel-body{padding:0}
.panel-table .panel-body .table-bordered{border-style: none;margin:0}
.panel-table .panel-body .table-bordered > thead > tr > th:first-of-type {text-align:center;width: 100px}
.panel-table .panel-body .table-bordered > thead > tr > th:last-of-type, .panel-table .panel-body .table-bordered > tbody > tr > td:last-of-type {border-right: 0px}
.panel-table .panel-body .table-bordered > thead > tr > th:first-of-type, .panel-table .panel-body .table-bordered > tbody > tr > td:first-of-type {border-left: 0px;}
.panel-table .panel-body .table-bordered > tbody > tr:first-of-type > td{border-bottom: 0px}
.panel-table .panel-body .table-bordered > thead > tr:first-of-type > th{border-top: 0px}
.panel-table .panel-footer{padding: 2px 10px;}
.panel-table .panel-footer .info{line-height:40px}
.panel-table .panel-footer .pagination{margin:0}
.panel-table .panel-footer .col{line-height: 34px;height: 34px}
.panel-table .panel-heading .col h3{line-height: 40px;height: 30px}
.panel-table .panel-body .table-bordered > tbody > tr > td{line-height: 40px}
.bg-msd{border-bottom-color: #001789;border-bottom-width: 2px}
.backFix{background-color:#fff}
md-content.md-default-theme, md-content{background-color:#fff}
.approveDenyToggle span.switch-right{background: #e81010!important;color: #fff!important}
.approveDenyToggle span.switch-left{padding-right:20px}

.msd-list-group{margin-bottom:0px}
.msd-list-group li.list-group-item:first-child{border-radius:0px}
.msd-list-group li.list-group-item{border-top: 1px solid #ddd;border-bottom:0px none transparent;border-left:0px none transparent;border-right:0px none transparent}
.msd-list-group li span.badge{float:none;background-color: #337ab7;}
.msd-list-group li span:nth-child(1){width:165px;display:inline-block}


.footer-links-dynamic{position: absolute;z-index: 100}

/*Author: Alex Turek; Date: 2/13/2018; Purpose: Used for navbar system message styling*/
/*.update-nag{font-size: 14px;text-align: left;/*background-color: #fff;border: 1px solid #d6eafb;-webkit-box-shadow: 0 1px 1px 0 rgba(0,0,0,.2);box-shadow: 0 1px 1px 0 rgba(0,0,0,.1);height: 40px;margin-bottom: 5px;margin-top:5px;display:inline-block}*/
.update-nag{font-size: 17px;text-align: left;/*background-color: #fff;border: 1px solid #d6eafb;-webkit-box-shadow: 0 1px 1px 0 rgba(0,0,0,.2);box-shadow: 0 1px 1px 0 rgba(0,0,0,.1);*/height: 40px;margin-bottom: 8px;margin-top:5px;display:inline-block}
.update-nag > .update-split{background: #337ab7;width: 33px;float: left;color: #fff!important;height: 33px;border-radius:50px;text-align: center}
.update-nag > .update-split > .glyphicon{position:relative;top: calc(50% - 9px)!important /* 50% - 3/4 of icon height */}
.update-nag > .update-split.update-success{background: #5cb85c!important}
.update-nag > .update-split.update-danger{background: #d9534f!important}
.update-nag > .update-split.update-info{background: #5bc0de!important}
.update-nag > .update-text{line-height: 19px;padding-top: 7px;padding-left: 38px;padding-right: 20px;font-style:italic;color: #fff}
.update-nag > .update-text > small{font-weight: bold; font-style:normal}

/*Author: Alex Turek; Date: 2/13/2018; Purpose: Differentiate between navbar system message and quotequeue system message styling*/
.update-nag-qq{font-size: 14px;text-align: left;/*background-color: #fff;border: 1px solid #d6eafb;-webkit-box-shadow: 0 1px 1px 0 rgba(0,0,0,.2);box-shadow: 0 1px 1px 0 rgba(0,0,0,.1);*/height: 40px;margin-bottom: 5px;margin-top:5px;display:inline-block}
.update-nag-qq > .update-split-qq{background: #337ab7;width: 33px;float: left;color: #fff!important;height: 33px;border-radius:50px;text-align: center}
.update-nag-qq > .update-split-qq > .glyphicon{position:relative;top: calc(50% - 9px)!important /* 50% - 3/4 of icon height*/}
.update-nag-qq > .update-split-qq.update-success{background: #5cb85c!important}
.update-nag-qq > .update-split-qq.update-danger{background: #d9534f!important}
.update-nag-qq > .update-split-qq.update-info{background: #5bc0de!important}
.update-nag-qq > .update-text-qq{line-height: 19px;padding-top: 7px;padding-left: 38px;padding-right: 20px;font-style:italic}
.update-nag-qq > .update-text-qq > small{font-weight: bold; font-style:normal}

.bg-success {background-color: #dff0d8!important}
.noMargin{margin:0px!important}

.input-group-addon.primary {color: rgb(255, 255, 255);background-color: rgb(50, 118, 177);border-color: rgb(40, 94, 142)}
.input-group-addon.success {color: rgb(255, 255, 255);background-color: rgb(92, 184, 92);border-color: rgb(76, 174, 76)}
.input-group-addon.info {color: rgb(255, 255, 255);background-color: rgb(57, 179, 215);border-color: rgb(38, 154, 188)}
.input-group-addon.warning {color: rgb(255, 255, 255);background-color: rgb(240, 173, 78);border-color: rgb(238, 162, 54);}
.input-group-addon.danger {color: rgb(255, 255, 255);background-color: rgb(217, 83, 79);border-color: rgb(212, 63, 58)}

.ui-grid{
    border:none 0px transparent!important;
}
/*Wrapping header text*/
.ui-grid-header-cell .ui-grid-cell-contents {
    height:40px;
     white-space: normal;
     -ms-text-overflow: clip;
     -o-text-overflow: clip;
     text-overflow: clip;
     overflow: visible;
     font-size:12px;
     text-align:left;
     padding:2px;
     margin-right:2px;
}
.ui-grid-header-cell .ui-grid-cell-contents{
     border-right:1px solid #bbb!important;
}
.ui-grid-header-cell:first-child .ui-grid-cell-contents,.ui-grid-header-cell:last-child .ui-grid-cell-contents{
    border-right:none 0px transparent!important;
}

.welcomeLink:hover .dropdown-menu{display:inline-block!important}
.name{font-size: 1.3em;font-weight: 700}
.c-info{padding: 5px 10px;font-size: 1.25em}

.msd-watermark{position: fixed;display:none;top: 39%;left: 29%;z-index: 1000;color: #bfbfbf;font-size: 60px;opacity: 0.5;-webkit-transform: rotate(-45deg);-moz-transform: rotate(-45deg);-o-transform: rotate(-45deg);transform: rotate(-45deg);}
.navbar-appNm-msd{color: #72c9c2;font-size: 20px;padding-top: 38px;padding-left: 40px;font-family: 'Roboto Condensed', sans-serif;float: left;font-weight: 800;}

@media screen {
    #printSection {display: none;}
}
@media print {
    div.container-fluid, footer, .modal, .modal-dialog{display:none;}
    /*#printSection, #printSection * {display: block;} */
    #printSection {position:absolute;left:0;top:0;}
    .msd-watermark {display:block}
}


/* (1366x768) WXGA Display */
@media  screen and (min-width: 1366px) {
    .dirty-mediaQuery1 span{display:block}
}

.navbar-toggle{background-color:#fff;margin-top: 28px;border-radius:0px}
.navbar-toggle .icon-bar {height: 4px;border-radius: 0px;background-color: #001789}
@media (min-width: 768px){
    .navbar {border-radius: 4px;}
}
.msdNavbar-text{padding:0px 10px;margin-left:20px;width:auto;line-height:30px;margin-top:15px;height:auto;}
.msdNavbar-nav{margin-top:5px;width:auto;margin-left:10px;float:right;}
.msdFooter-text{line-height:20px;}

@media (max-width: 768px){
    .msdNavbar-text{padding:0px 10px;margin-top:0px;margin-left:0px;}
    .navbar-appNm-msd{float:left;padding-left: 15px;padding-top: 5px;font-size: 16px;}
    .footer .footer-copy{float:none!important}
    .footer .footer-links{float:none!important;margin-top:-10px!important}
    .footer span{line-height: 19px!important;display: block;margin-top: 12px;}
    .msdFooter-text{margin-top:-12px !important;float:none !important;}
}
@media (max-width: 991px) and (min-width: 768px){
    .navbar-right{float:right!important;margin-right:0px;}
    .navbar-header{float:none}
    .navbar-brand-msd{margin-left: 38%;}
    .footer .footer-copy{float:none!important;margin-top:5px;}
    .footer .footer-links{margin-top:5px!important; float:none!important;}
    .msdFooter-text{width:auto;margin-top:-12px;}
    .msdNavbar-nav{float:right!important;}
    .msdNavbar-text{margin-top:0px;float:right;}
}
@media(min-width:1200px){
    .msdFooter-text{line-height:38px;}
}
@media(min-width:1920px){
    .msdNavbar-nav{margin-top:0px;}
}

.myGrid .ui-grid-header-canvas .ui-grid-header-cell:first-child span.ui-grid-invisible{visibility:hidden!important}
.ui-grid-top-panel{background:-webkit-gradient(linear, left bottom, left top, color-stop(0, #fff), color-stop(1, #fff))!important}
.ui-grid-cell-contents{border-bottom: solid 1px #ddd;padding:2px 2px!important}
.ui-grid-header-cell{background-color: #fff;border: 0px none transparent!important;border-bottom:#ddd solid 1px!important}
.ui-grid-cell{border-right-width: 0px!important}
.ui-grid-header-cell-row{background-color: #fff;}
.ui-grid-column-menu-button{top:6px!important}

.ui-grid-header-cell .ui-grid-cell-contents{/*display:inline-block*/}
.ui-grid-header-cell div[ui-grid-filter]{display:inline-block;position: absolute;right: 15px;top: 10px}
.ui-grid-header-cell .ui-grid-cell-contents{border-bottom: none 0px transparent}

.grey-tr,.grey .ui-grid-cell-contents{background-color: #b3b3b3 !important;color: rgba(0,0,0,0.87)!important;}
.gridRowSelected .ui-grid-cell-contents{
    background-color:rgba(52,152,219 ,.3) !important;
}
#filterFormControls .form-control{margin-bottom:5px}
.ui-grid-column-filter-button{outline:none}
/*.header-filtered{border-bottom-color:#62b75f!important}*/
/*.glyphChanges i{font-size:18px}
.glyphChanges i.text-warning{color:#f8c303}
    */
.glyphMSdStatus{background:url('assets/images/StatusIconSprite.png') no-repeat;width:16px;height:16px;display:block}
.glyphMSdStatus-Conclude{background-position:-783px -2px}
.glyphMSdStatus-ConcludeOneToMany{background-position:-783px -21px}
.glyphMSdStatus-InProgress{background-position:-259px -3px}
.glyphMSdStatus-InProgressOneToMany{background-position:-259px -22px}
.glyphMSdStatus-Cancelled{background-position:-134px -3px}
.glyphMSdStatus-CancelledOneToMany{background-position:-134px -22px}
.glyphMSdStatus-AwaitPay{background-position:-518px -3px}
.glyphMSdStatus-AwaitPayOneToMany{background-position:-518px -22px}
.glyphMSdStatus-PendingClose{background-position:-657px -2px}
.glyphMSdStatus-PendingCloseMany{background-position:-657px -21px}
.glyphMSdStatus-PendingCloseReached{background-position:1px -3px}
.glyphMSdStatus-PendingCloseReachedOneToMany{background-position:1px -22px}
.glyphMSdStatus-AwaitFlip{background-position:-392px -2px}
.glyphMSdStatus-AwaitFlipOneToMany{background-position:-392px -21px}


.glyphMSdStatus-Conclude{background-position:-783px -2px}
.ui-grid-pager-max-pages-number abbr{border-bottom:none!important}
.ui-grid-pager-count abbr{border:none!important}
.ui-grid-pager-max-pages-number{line-height:25px}
.ui-grid-pager-max-pages-number:before{content: "/";vertical-align:bottom}
.ui-grid-pager-max-pages-number abbr{display:none}

md-dialog .md-dialog-content{max-width: 460px; padding:0px!important}
md-dialog md-dialog-content .md-dialog-content-body{padding:10px!important}
.md-title{padding: 10px!important;background-color: #001789;color: #fff}
md-dialog .md-actions, md-dialog md-dialog-actions{border-top: 1px solid #ddd}
md-dialog{min-width:420px!important;max-width:420px!important}

select#compState option:first-child{color: #ccc}
.custom-text-muted{color:#999}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {opacity: 1}
.ui-grid-pager-max-pages-number::after{content:'  Page(s)'}
select.custom-text-muted>option:nth-child(1){color:#999}

.ui-grid-sort-priority-number { font-size: 0; }
[class^="ui-grid-icon"]:before,
[class*=" ui-grid-icon"]:before {
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  content:"\e150";
}
[class^="ui-grid-icon-up"]:before,
[class*=" ui-grid-icon-up"]:before{
    content:"\e155";
}
[class^="ui-grid-icon-down"]:before,
[class*=" ui-grid-icon-down"]:before{
    content:"\e156";
}



.list-group-item-info h5{font-weight:normal;}



/* jhipster-needle-css-add-main JHipster will add new css style */

.hr-custom{
    border:2px solid #001789!important;
    margin-top:30px;
}
.checkbox-custom{
    margin-top:25px;
    margin-bottom:10px;
    border:1px solid #ccc;
    box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075);
    padding:2px 5px 3px 20px;
    border-radius:5px;
    outline:0px;
}

.panel-default > .panel-heading{
background-color:#7ca1bf;
}

h4 > a > span{
font-weight:700;
}

/*Showing total of transaction amount TEMP*/
.form-control-mimic{
    border-radius:3px; border:1px solid #ccc; padding:4px 10px; width:100%;
     box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075);
}
/*Custom panels for judgment accounts*/
.panel-custom-input{
    border-radius:0px;
    border:1px solid #ddd;
}
.panel-custom-input .panel-body{
    margin:10px 0px;
}
.panel-custom-input .panel-heading{
    background-color:#337ab7;
    color:#fff;
    padding:2px 0px;
    border-radius:0px;
    border-bottom:1px solid #ddd;
}
.panel-custom-input .panel-heading>.row>div{
    border-right:1px solid #ddd;
    box-sizing:border-box;
}
.panel-custom-input .panel-heading>.row>div:last-child{
    border-right:0px;
}
.panel-custom-input .panel-heading label{
    padding-left:8px;
    line-height:30px;
    margin-bottom:0px;
}
.panel-custom-input div.form-group{
    padding:5px 2px;
    margin:0px;
}
.panel-custom-input input{
    padding:5px 10px;
    height:30px;
    width:100%;
    box-sizing:border-box;
    line-height:auto;
}

/*Displaying currency symbol*/
.currencyDisplay{
    position:relative;
}
.currencyDisplay:before{
    content:'$';
    position:absolute;
    display:block;
    top:5px;
    left:10px;
    z-index:9999;
}
.currencyDisplay input{
    padding-left:18px;
}

.btn-header-custom-position{
    margin-bottom:10px;
}
.well-custom-bg{
    background-color:#fff;
    box-shadow: inset 0px 0px 5px 0px #eee;
}
.custom-filter-outer{
    position:relative;
}
.custom-filter-inner{
    position: absolute;
    z-index: 9999;
    display: none;
    background-color: #fff;
    color: #222;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    width: 200px;
}
/*Screen for detecting clicks outside filter && blocking other actions*/
.custom-filter-outer{
    z-index:9990;
    display:block;
    color:rgba(255,255,255,0);
    width:100%;
    height:100%;
    position:fixed;
}
.filter-applied{
	color:#ff006c;
}

/*Modifying default loading bar css*/


/*Special handling item display*/
.msd-list-group li span.badge.special-handling-badge{
    padding:5px 10px;
    margin-right:2px;
}

.myGrid .ui-grid-render-container-body .ui-grid-viewport{
    height: calc(100% - 42px) !important;
}

/*Usage Reports table*/
.panel-table .panel-body .table-bordered.table-usage-report{table-layout: fixed;width:100%;}
.panel-table .panel-body .table-bordered.table-usage-report > thead > tr > th{line-height: 25px; text-align:center;}
.panel-table .panel-body .table-bordered.table-usage-report > tbody > tr > td{line-height: 20px; text-align:center;max-width:100%; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.panel-table .panel-body .table-bordered.table-usage-report > tfoot > tr > td{line-height: 20px; text-align:center;max-width:100%; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}

/*highlight rows with result summary*/
.panel-table .panel-body .table-bordered.table-usage-report > tbody > tr.company-total{background-color:rgba(51, 122, 183, 0.08);}
.panel-table .panel-body .table-bordered.table-usage-report > tfoot > tr{background-color: rgba(0, 150, 136, 0.22);}

/*No borders next to total/company total*/
.panel-table .panel-body .table-bordered.table-usage-report > tbody > tr > td.no-border-right{border-right:0px;}
.panel-table .panel-body .table-bordered.table-usage-report > tbody > tr > td.no-border-right+td{border-left:0px;}
.panel-table .panel-body .table-bordered.table-usage-report > tfoot > tr > td.no-border-right{border-right:0px;}
.panel-table .panel-body .table-bordered.table-usage-report > tfoot > tr > td.no-border-right+td{border-left:0px;}

/*Left align text for User/Company Names*/
.panel-table .panel-body .table-bordered.table-usage-report > thead > tr > th.report-user-name{width:27.5%; text-align:left; padding-left:16px;}
.panel-table .panel-body .table-bordered.table-usage-report > thead > tr > th.report-user-data-lg{width:15.5%;}
.panel-table .panel-body .table-bordered.table-usage-report > tbody > tr > td.report-user-name{text-align:left; padding-left:16px;}
.panel-table .panel-body .table-bordered.table-usage-report > tfoot > tr > td.report-user-name{text-align:left; padding-left:16px;}

/*****BEGIN GLOBAL OVERRIDES*****/
.noShow { display: none; }
.loginControl { overflow: hidden; padding-bottom: 10px; }
.helpLinks { padding-top: 20px; clear: both; font-size: 95%; }
/*****END GLOBAL OVERRIDES*****/

/*****BEGIN BUTTON COLOR OVERRIDES*****/
.btn-primary {
    background-color: #001789;
    border-color: #001789;
    border-radius: 10px;
    text-transform: uppercase;
}
.btn-primary:hover {
    color: #fff;
    background-color: #247FC5;
    border-color: #204d74;
}
.centerLink { display: block; width: 100%; text-align: center; }
/*****END BUTTON COLOR OVERRIDES*****/

/*****BEGIN ENTRY TITLE*****/
.entry-header__regular {
    color: #ffffff;
    background: linear-gradient(to top, rgba(0,0,0,.25) 0%, rgba(0,0,0,0)75%), url(assets/images/msd-header-repeat.png);
    background-repeat: no-repeat, repeat;
    background-size: cover, 240px 16px;
}

    .entry-header__featured {
      height: 350px;
    }

    .entry-header__regular .entry-title {
      max-width: 100rem;
      padding: 2rem 2.5rem;
      margin: 0 auto;
      font-size: 3.75rem;
      font-weight: 300;
      text-align: center
    }

    .entry-header__featured .entry-title {
      min-width: 450px;
      font-size: 3.75rem;
    }

    .mat-button-toggle-label-content{
        line-height: 38px !important;
    }
    .mat-mdc-table .mdc-data-table__row:nth-child(2n+1){
		height: 30px !important;
        background-color: #edf0ef !important;
	}
    .mat-mdc-table .mdc-data-table__row:not(:nth-child(2n+1)){
		height: 30px !important;
        background-color:#ffffff !important;
	}
    .mat-mdc-table .mdc-data-table__row:not(:nth-child(2n+1)){
		height: 30px !important;
        background-color:#ffffff !important;
	}


.mat-mdc-header-row {
  width: 100%;
  min-height: 20px;
  padding: 0px 0px 0px 4px;
}

.mat-mdc-header-cell {
    justify-content: left;
    font-size: 13px;
    font-weight: bold;
    padding: 0px 0px 0px 4px;
}

/*
 .mat-mdc-row {
    width: 100%;
  min-height: 12px;
  display: inline-flex;
  min-width: 100%;
}

*/
.mat-mdc-cell {
  justify-content: left;
  border-top: 1px solid crimson;
  font-size: 13px;
  padding: 0px 0px 0px 4px;
  text-align: left;
  align-items: flex-start;
}

.mat-mdc-cell-points {
  flex: 0 0 20px !important;
  white-space: unset !important;
}

.mat-mdc-cell-concession{
  flex: 0 0 200px !important;
  white-space: unset !important;
  justify-content: left;
  border-top: 1px solid crimson;
  font-size: 13px;
  padding: 0px 0px 0px 4px;
  text-align: left;
}

/******************************************/



/*****END ENTRY TITLE*****/
.mdc-circular-progress{
    position:absolute !important;
}

md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar{color: rgb(33, 63, 87);background: rgb(33, 63, 87);}

md-tabs.md-default-theme .md-tab.md-active, md-tabs .md-tab.md-active, md-tabs.md-default-theme
.md-tab.md-active md-icon, md-tabs .md-tab.md-active md-icon, md-tabs.md-default-theme
.md-tab.md-focused, md-tabs .md-tab.md-focused, md-tabs.md-default-theme
.md-tab.md-focused md-icon, md-tabs .md-tab.md-focused
 md-icon{color: rgb(255, 255, 255);background: #337ab7}
md-tabs.md-default-theme .md-tab, md-tabs .md-tab{font-weight:bold}


.mat-tab-label {
    min-width: 25px !important;
    padding: 5px;
    font-weight: bold;
}

/* Styles for the active tab label */
.mat-tab-label.mat-tab-label-active {
    min-width: 25px !important;
    padding: 5px;
    background-color: #337ab7;
    color: rgb(255, 255, 255);
    font-weight: bold;
}

/* Styles for the ink bar */
.mat-ink-bar {
    background-color: green;
}



.my-custom-header {
    font-size: 20px !important;
    font-weight: 500 !important;
    letter-spacing: 0.005em !important;
    background-color: #001789 !important;
    color: #ffff !important;
}
.my-custom-body {
    font-size: 18px !important;
    border-radius: 10px !important;
}

